import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import "./styles/project.css";

const Project = (props) => {
	const { date, logo, title, description, linkText, link } = props;

	return (

		// <React.Fragment>
		// 	<div className="article">
		// 		<div className="article-left-side">
		// 			<div className="article-date">{date}</div>
		// 		</div>

		// 		<Link to={link}>
		// 			<div className="article-right-side">
		// 				<div className="article-title">{title}</div>
		// 				<div className="article-description">{description}</div>
		// 				<div className="article-link">
		// 					Read More{" "}
		// 					<FontAwesomeIcon
		// 						style={{ fontSize: "10px" }}
		// 						icon={faChevronRight}
		// 					/>
		// 				</div>
		// 			</div>
		// 		</Link>
		// 	</div>
		// </React.Fragment>

		<React.Fragment>
			<div className="homepage-article">
				<div className="homepage-article-content">
					<div className="homepage-article-date">
						|&nbsp;&nbsp;&nbsp;{date}
					</div>
					<div className="homepage-article-title">{title}</div>
					<div className="homepage-article-description">
						{description}
					</div>
					<div className="homepage-article-link">
						<Link to={link}>
							Read More{" "}
							<FontAwesomeIcon
								style={{ fontSize: "10px" }}
								icon={faChevronRight}
							/>
						</Link>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Project;
