import React from "react";
import Project from "./project";
import INFO from "../../data/user";
import "./styles/allProjects.css";

const AllResearches = () => {
    return (
        <div className="all-projects-container">
            {INFO.researches.map((research, index) => (
                <div className="all-projects-project" key={index}>
                    <Project
                        date={research.date}
                        logo={research.logo}
						title={research.title}
						description={research.description}
						linkText={research.linkText}
						link={"/researches/" + (index + 1)}

                    />
                </div>
            ))}
        </div>

   
    );
};

export default AllResearches;
