import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import AllCertificates from "../components/certifications/allCertificates";

import INFO from "../data/user";
import SEO from "../data/seo";

import "./styles/projects.css";

const Certifications = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const currentSEO = SEO.find((item) => item.page === "certifications");

	return (
		<React.Fragment>
			<Helmet>
				<title>{`Certifications | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content">
				<NavBar active="certifications" />
				<div className="content-wrapper">
					<div className="projects-logo-container">
						<div className="projects-logo">
							<Logo width={46} />
						</div>
					</div>
					<div className="projects-container">
						<div className="title projects-title">
							Certifications 
						</div>

						<div className="subtitle projects-subtitle">
                            I always try to set goals , step out of my comfort zone and 
                            challenge myself to grow and improve my skillsets. I have taken several onlines courses on my own to 
                           , participated in multiple hackathons,  and taken part in research projects for which 
                            I have received certifications as shown here. 

						</div>

						<div className="projects-list">
							<AllCertificates />
						</div>
					</div>
					<div className="page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Certifications;
