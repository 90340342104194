

const INFO = {
	main: {
		title: "Portfolio",
		name: "Sijan P..",
		email: "sijuichi@gmail.com",
		logo: "../logo.jpg",
	},

	socials: {
		twitter: "https://twitter.com/",
		github: "https://github.com/sijan67",
		linkedin: "https://www.linkedin.com/in/sijan-poudel-24b036174/",
		instagram: "https://instagram.com/",
		stackoverflow: "https://stackoverflow.com/",
		facebook: "https://facebook.com/",
	},

	homepage: {
		title: "Dreamer, Optimist, Developer and Change Enthusiast",
		description:
			"Hello! My name is Sijan Poudel, a fourth-year Computer Engineering student at the University of British Columbia. I love coming up with new ideas , building new things, and making a positive impact through my involvement in research, projects, and volunteer works. I am a dreamer, optimist and I am always eager to grow and learn new lessons.",
	},

	about: {
		title: "I’m Tharindu N. I live in Sri Lanka, where I design the future.",
		description:
			"I've worked on a variety of projects over the years and I'm proud of the progress I've made. Many of these projects are open-source and available for others to explore and contribute to. If you're interested in any of the projects I've worked on, please feel free to check out the code and suggest any improvements or enhancements you might have in mind. Collaborating with others is a great way to learn and grow, and I'm always open to new ideas and feedback.",
	},

	articles: {
		title: "Work",
		description:
			"I have extensive experience working for industries where I have developed my skills over years.",
	},

	involvements: {
		title: "Involvements",
		description:
			"I also actively participate in volunteering , leadership roles and love contributing back to the community I am part of.",
	},

	researches: [
		{
			date: "Jan - April 2023", 
			title: "Exploring the MLP Mixer Architecture for Image Classification",
			description:
				"Wrote paper on Performance Evaluation, Tuning, and Ablation Study on MNIST Dataset",
			logo: "../researches/research-1.png",
			linkText: "View Research",
			link: "https://github.com",
		},
		{
			date: "Sep - Dec 2022", 
			title: "Comparative Study on MS Tool Extraction",
			description:
				"Conducted paper extraction, data refinement, documented relationships, and executed tools for comparative analysis in MS extraction.",
			logo: "../researches/research-2.png",
			linkText: "View Research",
			link: "https://github.com",
		},
		{
			date: "Sep 2021 - April 2022", 
			title: "Social-Net: objective and automated assessment of chronic social isolation‐induced behavior deficits using machine learning",
			description:
				"Classified and studied the aggressive behaviour of mice after 7 weeks of chronic social isolation compared to that of the group-housed mice using ML model.",
			logo: "../researches/research-3.png",
			linkText: "View Research",
			link: "https://github.com",
		},
		
	],

	projects: [
		{
			title: "Just For Kiks",
			description:
				"De1SoC, Verilog, JavaScript, MongoDB, React Native, Expo",
			logo: "../projects/jfk.gif",
			linkText: "View Github",
			link: "https://github.com/sijan67/Just-For-Kiks-Party-Games",
		},

		{
			title: "Gruwup",
			description:
				"Java, Android Studio,  JavaScript, MongoDB",
			logo: "../projects/gruwup.gif",
			linkText: "View Github",
			link: "https://github.com/gruwup/gruwup",
		},

		{
			title: "Queue Detection Project",
			description:
				"Python, Streamlit, Jetson Nano, Yolov4, Tensorflow Object Detection API",
			logo: "../projects/queue_counter.gif",
			linkText: "View Github",
			link: "https://github.com/UBCSmartCity/QueueDetection",
		},

		{
			title: "UBC Safewalk App",
			description:
				"JavaScript, MongoDB, React Native, Expo",
			logo: "../projects/sw.gif",
			linkText: "View Github",
			link: "https://github.com/UBCSmartCity/UBC-Safewalk-App",
		},


		{
			title: "CozyCommute",
			description:
				"Python, FastAPI,  React Native, Expo, Translik Open API",
			logo: "../projects/cozycommute.gif",
			linkText: "View Github",
			link: "https://github.com/sijan67/ThinkTECH",
		},

		{
			title: "AI-Enhanced Waste Survellience System",
			description:
				"Python, Streamlit, Jetson Nano",
			logo: "../projects/waste_surv.gif",
			linkText: "View Github",
			link: "https://github.com/sijan67/OceanTechChallenge",
		},


		{
			title: "Hopify",
			description:
				"Vue.js and Firebase",
			logo: "../projects/hopify.gif",
			linkText: "View Github",
			link: "https://github.com/katherine9898/Hack4Health",
		},

		{
			title: "OMOU",
			description:
				"Python, Pytorch, TensorFlow, Keras and Django",
			logo: "../projects/omou.gif",
			linkText: "View Github",
			link: "https://github.com/sijan67/OMOU",
		},


		{
			title: "Meme Generator",
			description:
				"React.js",
			logo: "../projects/meme.gif",
			linkText: "View Github",
			link: "https://github.com",
		},

		{
			title: "EcoHome App",
			description:
				"Figma",
			logo: "../projects/ecohome.gif",
			linkText: "View Figma Design",
			link: "https://www.figma.com/file/VYmES8V5ui3ql0QoAe8ZkI/Untitled?type=design&node-id=0%3A1&mode=design&t=4sLJ2v7nJIIIIJwI-1",
		},
	],

	// certifications:[
	// 	{
	// 		title: "Project 1",
	// 		description:
	// 			"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
	// 		img: "../certificates/certificate-1.png",
	// 		linkText: "View Certificate",
	// 		link: "https://github.com",
	// 	},
	// 	{
	// 		title: "Project 1",
	// 		description:
	// 			"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
	// 		img: "../certificates/certificate-2.png",
	// 		linkText: "View Certificate",
	// 		link: "https://github.com",
	// 	},
	// 	{
	// 		title: "Project 1",
	// 		description:
	// 			"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
	// 		img: "../certificates/certificate-3.png",
	// 		linkText: "View Certificate",
	// 		link: "https://github.com",
	// 	},
	// 	{
	// 		title: "Project 1",
	// 		description:
	// 			"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
	// 		img: "../certificates/tcp2.png",
	// 		linkText: "View Certificate",
	// 		link: "https://github.com",
	// 	},
	// 	{
	// 		title: "Project 1",
	// 		description:
	// 			"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
	// 		img: "../certificates/hazard-material-prev.png",
	// 		linkText: "View Certificate",
	// 		link: "https://github.com",
	// 	},
	// 	{
	// 		title: "Project 1",
	// 		description:
	// 			"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
	// 		img: "../certificates/workplace-bully-prev.png",
	// 		linkText: "View Certificate",
	// 		link: "https://github.com",
	// 	},
	// 	{
	// 		title: "Project 1",
	// 		description:
	// 			"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
	// 		img: "../certificates/privacy.png",
	// 		linkText: "View Certificate",
	// 		link: "https://github.com",
	// 	},
	// 	{
	// 		title: "Project 1",
	// 		description:
	// 			"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
	// 		img: "../certificates/jlpt-certificate.png",
	// 		linkText: "View Certificate",
	// 		link: "https://github.com",
	// 	},
	// 	{
	// 		title: "Project 1",
	// 		description:
	// 			"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
	// 		img: "../certificates/certificate-4.png",
	// 		linkText: "View Certificate",
	// 		link: "https://github.com",
	// 	},
	// 	{
	// 		title: "Project 1",
	// 		description:
	// 			"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
	// 		img: "../certificates/certificate-5.png",
	// 		linkText: "View Certificate",
	// 		link: "https://github.com",
	// 	},
	// 	{
	// 		title: "Project 1",
	// 		description:
	// 			"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
	// 		img: "../certificates/certificate-6.png",
	// 		linkText: "View Certificate",
	// 		link: "https://github.com",
	// 	},
	// 	{
	// 		title: "Project 1",
	// 		description:
	// 			"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
	// 		img: "../certificates/certificate-7.png",
	// 		linkText: "View Certificate",
	// 		link: "https://github.com",
	// 	},
	// 	{
	// 		title: "Project 1",
	// 		description:
	// 			"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
	// 		img: "../certificates/certificate-9.png",
	// 		linkText: "View Certificate",
	// 		link: "https://github.com",
	// 	},
	// 	{
	// 		title: "Project 1",
	// 		description:
	// 			"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
	// 		img: "../certificates/certificate-10.png",
	// 		linkText: "View Certificate",
	// 		link: "https://github.com",
	// 	},
	// 	{
	// 		title: "Project 1",
	// 		description:
	// 			"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
	// 		img: "../certificates/edt.png",
	// 		linkText: "View Certificate",
	// 		link: "https://github.com",
	// 	},
	// 	{
	// 		title: "Project 1",
	// 		description:
	// 			"Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
	// 		img: "../certificates/edt2.png",
	// 		linkText: "View Certificate",
	// 		link: "https://github.com",
	// 	},

	// ]

	certifications: [
        // ... existing certifications

        // If certificates are added change length by num of certificates added 
        // This code generates certificates from 1 to 18 with corresponding image paths
        ...Array.from({ length: 17 }, (_, i) => ({
            title: `Certificate ${i + 1}`,
            description: "Lorem ipsum dolor sit amet. Et incidunt voluptatem ex tempore repellendus qui dicta nemo sit deleniti minima.",
            img: `../certificates/certificate-${i + 1}.png`,
            linkText: "View Certificate",
        })),
    ],
};

export default INFO;
