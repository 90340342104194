import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";

function createCertificate(date, title, description="", imgPath, keywords, linkExist=false, link="", linkText="", style = "") {
    return () => ({
        date,
        title,
        description,
        keywords,
        linkExist,
        link,
        linkText,
        style,
        body: (
            <React.Fragment>
                <div className="article-content">
                    {/* <div className="paragraph">{title}</div> */}
                    <img
                        src={imgPath}
                        alt="certificate"
                        className="randImage"
                        width="80%"
                    />
                    <div className="paragraph">{description}</div>

                    {linkExist && <>
                        <Link to={link}>
                            <div className="project-container">
                            
                                <div className="project-link">
                                    <div className="project-link-icon">
                                        <FontAwesomeIcon icon={faLink} />
                                    </div>

                                    <div className="project-link-text">{linkText}</div>
                                </div>
                            </div>
                        </Link>
                    </>}
                    

                </div>
            </React.Fragment>
        ),
    });
}

const myCertificates = [
    createCertificate(
        "29 October 2023",
        "IEEEXtreme 17.0 Programming Competition",
        "",
        "../certificates/certificate-1.png",
        [],
    ),
    createCertificate(
        "August 2021",
        "OPPOHack 2021 Global Innovation Competition",
        "",
        "../certificates/certificate-2.png",
        [],
    ),
	createCertificate(
        "April 2022",
        "UBC: Undergraduate Research Opportunities Research Experience Program Completion",
        "",
        "../certificates/certificate-3.png",
        [],
    ),
    createCertificate(
        "26 May 2021",
        "Hack4Health 2021 Hackathon Competition",
        "",
        "../certificates/certificate-4.png",
        [],
        false,
        "https://github.com/katherine9898/Hack4Health",
        "Github"
    ),
	createCertificate(
        "20 May 2020",
        "Linkedin: Communicating in the Language of Leadership Course Completion",
        "",
        "../certificates/certificate-5.png",
        [],
    ),
	createCertificate(
        "3 Aug 2020",
        "Linkedin: Algorithmic Trading and Stocks Essential Training Course Completion",
        "",
        "../certificates/certificate-6.png",
        [],
    ),
	createCertificate(
        "6 Aug 2020",
        "Linkedin: AI Accountability Essential Training Course Completion",
        "",
        "../certificates/certificate-7.png",
        [],
    ),
	createCertificate(
        "5 Oct 2021",
        "UBC: Preventing and Addressing Workplace Bullying and Harassment Course Completion",
        "",
        "../certificates/certificate-8.png",
        [],
    ),
	createCertificate(
        "10 Aug 2021",
        "Kaggle: Python Course Completion",
        "",
        "../certificates/certificate-9.png",
        [],
    ),
	createCertificate(
        "15 Aug 2021",
        "Kaggle: Intro to Machine Learning Course Completion",
        "",
        "../certificates/certificate-10.png",
        [],
    ),
	createCertificate(
        "Sep 2021",
        "UBC: Engineering Design Team Safety Orientation Course Completion 2021/2022",
        "",
        "../certificates/certificate-11.png",
        [],
    ),
	createCertificate(
        "Sep 2022",
        "UBC: Engineering Design Team Safety Orientation Course Completion 2022/2023",
        "",
        "../certificates/certificate-12.png",
        [],
    ),
	createCertificate(
        "5 Oct 2021",
        "UBC: General Audience Workplace Hazardous Materials Information System (WHMIS) Course Completion",
        "",
        "../certificates/certificate-13.png",
        [],
    ),
	createCertificate(
        "4 July 2021",
        "JLPT N2 Level Exam Certification",
        "",
        "../certificates/certificate-14.png",
        [],
    ),
	createCertificate(
        "14 May 2020",
        "Panel On Research Ethics: TCP2 Core Course Completion",
        "",
        "../certificates/certificate-15.png",
        [],
    ),
	createCertificate(
        "5 October, 2021",
        "UBC: Privacy and Information Security Fundamentals Part 1 Course Completion",
        "",
        "../certificates/certificate-16.png",
        [],
    ),
    createCertificate(
        "8 Aug 2020",
        "Linkedin: Artificial Intelligence Foundations, Machine Learning Course Completion",
        "",
        "../certificates/certificate-17.png",
        [],
    ),

    // Add more certificates as needed
];

export default myCertificates;
