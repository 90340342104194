import React from "react";

function createProject(date, title, description, imgPath, keywords, style = "") {
    return () => ({
        date,
        title,
        description,
        keywords,
        style,
        body: (
            <React.Fragment>
                <div className="article-content">
                    <div className="paragraph">{title}</div>
                    <img
                        src={imgPath}
                        alt="project"
                        className="randImage"
                        width="80%"
                    />
                </div>
            </React.Fragment>
        ),
    });
}

const myProjects = [
    createProject(
        "7 May 2023",
        "Project 1",
        "Cloud computing offers a range of benefits, including cost savings and increased flexibility. Find out why more businesses are turning to the cloud.",
        "../projects/project-1.png",
        ["The Benefits of Cloud Computing", "Tharindu", "Tharindu N", "Tharindu Nayanajith"]
    ),
    createProject(
        "7 May 2023",
        "Artificial Intelligence in Healthcare",
        "AI is transforming the healthcare industry, from improving patient outcomes to streamlining operations. Discover the latest applications of this game-changing technology.",
        "../projects/project-2.png",
        ["Artificial Intelligence in Healthcare", "Tharindu", "Tharindu N", "Tharindu Nayanajith"]
    ),
    // Add more projects as needed
];

export default myProjects;
